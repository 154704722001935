import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import './style.scss'
import { Link } from "react-router-dom";
import BlogsService from "../../../services/BlogsService";

const Blogs = () => {
    const [data, setData] = useState([])
    const [tab, setTab] = useState("global")
    const [hasData, setHasData] = useState(null)
    const [search, setSearch] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const Auth = useSelector((state) => state.auth?.auth);
    const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
    const blogsService = new BlogsService()

    useEffect(()=>{
      let params= {
        type: tab
      }
      blogsService.getList(params).then(res=> {
        if(res?.status){
          setData(res?.data?.data)
          if (res.data?.data?.length > 0) {
            setHasData(1);
          } else {
            setHasData(0);
          }
        }
      })
    }, [tab, shouldUpdate])

    return (
      <div className="department">
        <Card className="mb-3">
          <Card.Body className="search-card d-flex justify-content-between p-3 align-items-center">
            <div className="input-group w-50">
              <input 
                  type="text" 
                  style={{borderRadius: '8px',
                  color: 'initial',
                  padding: '18px 16px'}}
                  className="form-control"
                  placeholder={`${Translate[lang]?.search_by} I.D, ${Translate[lang]?.name}`}
                  value={search}
                  onChange={e=> setSearch(e.target.value)} 
              />
              <div className="flaticon-381-search-2"
                style={{position: 'absolute',zIndex:'1', right: lang === 'en' && '16px', left: lang === 'ar' && '16px', top: '50%', transform: 'translate(0, -50%)'}}
              ></div>
            </div>
            <div>
              {<Link to='/blogs/add-blogs' className='me-2 h-7 btn btn-primary'>
                <i className="la la-plus mx-1"></i>
                {Translate[lang]?.add} {Translate[lang]?.blogs}
              </Link>}
              {/* {isExist('blogs')*/}
            </div>
          </Card.Body >
        </Card>
        
        <Card>
          <Card.Body>
          <div className="tabs-div">
          <span
            className="cursor-pointer"
            style={{
              padding: "5px 16px",
              color: tab === "global" ? "var(--primary)" : "#7E7E7E",
              borderBottom: tab === "global" ? "2px solid" : "none",
            }}
            onClick={() => setTab("global")}
          >
            {Translate[lang].global_blogs}
          </span>
          <span
            className="cursor-pointer mx-2"
            style={{
              padding: "5px 16px",
              color: tab === "pic_me" ? "var(--primary)" : "#7E7E7E",
              borderBottom: tab === "pic_me" ? "2px solid" : "none",
            }}
            onClick={() => setTab("pic_me")}
          >
            {Translate[lang].picme_blogs}
          </span>
        </div> 
          </Card.Body>
        </Card>

        {tab === 'global' && <Row>
          <Col lg={12}>
            <Card>
              <Card.Body className={`${hasData === 0 && 'text-center'} `}>
              {loading && <div style={{height: '300px'}}>
                  <Loader />
                </div>}
                {(hasData === 1 && !loading) && <Table responsive>
                  <thead>
                    <tr className='text-center'>
                      <th>
                        <strong>I.D</strong>
                      </th>
                      <th>
                        <strong>{Translate[lang]?.image}</strong>
                      </th>
                      <th>
                        <strong>{Translate[lang]?.title}</strong>
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className="table-body">
                      {data?.map((item, index) =>{
                          return <CardItem 
                              index= {index}
                              key= {index}
                              item={item}
                              setShouldUpdate={setShouldUpdate}
                          />
                      })}
                  </tbody>
                </Table>}
                {hasData === 0 && <NoData />}
              </Card.Body>
            </Card>
          </Col>
        </Row>}
        {tab === 'pic_me' && <Row>
          <Col lg={12}>
            <Card>
              <Card.Body className={`${hasData === 0 && 'text-center'} `}>
              {loading && <div style={{height: '300px'}}>
                  <Loader />
                </div>}
                {(hasData === 1 && !loading) && <Table responsive>
                  <thead>
                    <tr className='text-center'>
                      <th>
                        <strong>I.D</strong>
                      </th>
                      <th>
                        <strong>{Translate[lang]?.image}</strong>
                      </th>
                      <th>
                        <strong>{Translate[lang]?.title}</strong>
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className="table-body">
                      {data?.map((item, index) =>{
                          return <CardItem 
                              index= {index}
                              key= {index}
                              item={item}
                              setShouldUpdate={setShouldUpdate}
                          />
                      })}
                  </tbody>
                </Table>}
                {hasData === 0 && <NoData />}
              </Card.Body>
            </Card>
          </Col>
        </Row>}
      </div>
    );
};

export default Blogs;
