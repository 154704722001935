import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/gallery";

export default class GalleryService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
    postFashion(data){
        return http.post(`${apiEndpoint}/fashion`, data)
    }

    getFashion(){
        return http.get(`${apiEndpoint}/fashion`)
    }
}
