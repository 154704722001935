import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import BaseService from "../../../../services/BaseService";
import Loader from "../../../common/Loader";
import uploadImg from "../../../../images/upload-img.png";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';
import Select from 'react-select';
import BlogsService from "../../../../services/BlogsService";
import { useLocation, useNavigate } from "react-router-dom";


const AddBlogs = ()=>{
    const initial = {
        title_en:'',
        title_ar:'',
        title_es: '',
        title_it: '',
        title_ru: '',
        title_fr: '',
        title_de: '',
        description_ar: EditorState.createEmpty(),
        description_en: EditorState.createEmpty(),
        description_es: EditorState.createEmpty(),
        description_it: EditorState.createEmpty(),
        description_ru: EditorState.createEmpty(),
        description_fr: EditorState.createEmpty(),
        description_de: EditorState.createEmpty(),
        image: {src: '', loading: false},
    }
    const [formData, setFormData] = useState(initial)
    const lang = useSelector(state=> state.auth.lang)
    const types = [
        {label: Translate[lang].global_blogs, value: 'global'},
        {label: Translate[lang].picme_blogs, value: 'pic_me'},
        {label: Translate[lang].video, value: 'video'},
    ]
    const [type, setType] = useState('')
    const [isAdd, setIsAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const blogsService = new BlogsService()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if(!location.state){
            setIsAdd(true)
        } else {
            setType(types?.find(res=> res?.value === location.state.type))
            setIsAdd(false)
            let data = {
                id: location.state?.id,
                title_en: location.state?.title_en,
                title_ar: location.state?.title_ar,
                title_es: location.state?.title_es,
                title_it: location.state?.title_it,
                title_ru: location.state?.title_ru,
                title_fr: location.state?.title_fr,
                title_de: location.state?.title_de,
                description_ar: !!location.state.description_ar ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state.description_ar))) : '',
                description_en: !!location.state.description_en ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state.description_en))) : '',
                description_es: !!location.state.description_es ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state.description_es))) : '',
                description_it: !!location.state.description_it ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state.description_it))) : '',
                description_ru: !!location.state.description_ru ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state.description_ru))) : '',
                description_de: !!location.state.description_de ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state.description_de))) : '',
                description_fr: !!location.state.description_fr ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location.state.description_fr))) : '',
                link: location.state.link,
                image: {src: location.state.image, loading: false}
            }
            setFormData(data)
        }
    },[location])

    useEffect(()=>{
        if(type?.value === 'video'){
            setLoading(true)
            blogsService.getBlogVideo().then(res=>{
                if(res?.status){
                    setFormData({
                        ...formData,
                        image: {src: res?.data?.data?.url, loading: false}
                    })
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        }
    }, [type])

    const fileHandler = (e) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        setFormData({...formData, image: {src: '', loading: true}})

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.status) {
            setFormData({...formData, image: {src: res.data.url, loading: false}})
          }
        });
    };

    const submit = (e) =>{
        e.preventDefault();
        setLoading(true)
        if(type?.value !== 'video'){
            let data = {
                title_en: formData?.title_en,
                title_ar: formData?.title_ar,
                image: formData?.image?.src,
                type: type?.value
            }
            if(!!formData?.title_es) data['title_es'] = formData?.title_es;
            if(!!formData?.title_it) data['title_it'] = formData?.title_it;
            if(!!formData?.title_ru) data['title_ru'] = formData?.title_ru;
            if(!!formData?.title_fr) data['title_fr'] = formData?.title_fr;
            if(!!formData?.title_de) data['title_de'] = formData?.title_de;
            
            if(type?.value === 'global') data['link'] = formData?.link
            if(type?.value === 'pic_me'){
                data["description_en"]= draftToHtml(convertToRaw(formData.description_en.getCurrentContent()))
                data["description_ar"]= draftToHtml(convertToRaw(formData.description_ar.getCurrentContent()))
                if(!!formData.description_es) data["description_es"]= draftToHtml(convertToRaw(formData.description_es.getCurrentContent()))
                if(!!formData.description_fr) data["description_fr"]= draftToHtml(convertToRaw(formData.description_fr.getCurrentContent()))
                if(!!formData.description_de) data["description_de"]= draftToHtml(convertToRaw(formData.description_de.getCurrentContent()))
                if(!!formData.description_it) data["description_it"]= draftToHtml(convertToRaw(formData.description_it.getCurrentContent()))
                if(!!formData.description_ru) data["description_ru"]= draftToHtml(convertToRaw(formData.description_ru.getCurrentContent()))
            }
            if(isAdd){
                blogsService.create(data)?.then(res=>{
                    if(res && res?.status === 201){
                        toast.success('Blog Added Successfully')
                        navigate('/blogs')
                    }
                    setLoading(false)
                }).catch(()=>setLoading(false))
            } else {
                blogsService.update(formData?.id, data)?.then(res=>{
                    if(res && res?.status === 200){
                        toast.success('Blog Updated Successfully')
                        navigate('/blogs')
                    }
                    setLoading(false)
                }).catch(()=>setLoading(false))
            }
        } else {
            let data = {
                url: formData.image?.src
            }
            blogsService.addBlogVideo(data)?.then(res=>{
                if(res && res?.status === 201){
                    toast.success('Video Added Successfully')
                    navigate('/blogs')
                }
                setLoading(false)
            }).catch(()=>setLoading(false))
        }
    }

    return(
        <Card>
        <Card.Body>
            <AvForm className='form-horizontal' onValidSubmit={submit}>
            <div>
                    <Row>
                        {isAdd && <Col md={6} className="mb-3" style={{zIndex: '9'}}>
                            <label>{Translate[lang].type}</label>
                            <Select
                                value={type} name="type"
                                placeholder={Translate[lang]?.select}
                                options={types}
                                onChange={(e)=> setType(e)}
                            />
                        </Col>}
                        {isAdd && <Col md={6} className="mb-3"></Col>}
                        {(type?.value === 'global' || type?.value === 'pic_me') && <>
                        <Col md={6}>
                            <AvField
                                label={`${Translate[lang].english_title}*`}
                                type='text'
                                placeholder={Translate[lang]?.english_title}
                                bsSize="lg"
                                name='english_title'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.title_en}
                                onChange={(e) => setFormData({...formData, title_en: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={`${Translate[lang].arabic_title}*`}
                                type='text'
                                placeholder={Translate[lang]?.arabic_title}
                                bsSize="lg"
                                name='arabic_title'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.title_ar}
                                onChange={(e) => setFormData({...formData, title_ar: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].spanish_title}`}
                                name ={`title_es`}
                                type="text"
                                bsSize="lg"
                                value={formData.title_es}
                                placeholder={Translate[lang].spanish_title}
                                onChange={(e) => setFormData({...formData, title_es: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].italian_title}`}
                                name ={`title_it`}
                                type="text"
                                bsSize="lg"
                                value={formData.title_it}
                                placeholder={Translate[lang].italian_title}
                                onChange={(e) => setFormData({...formData, title_it: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].russian_title}`}
                                name ={`title_ru`}
                                type="text"
                                bsSize="lg"
                                value={formData.title_ru}
                                placeholder={Translate[lang].russian_title}
                                onChange={(e) => setFormData({...formData, title_ru: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].french_title}`}
                                name ={`title_fr`}
                                type="text"
                                bsSize="lg"
                                value={formData.title_fr}
                                placeholder={Translate[lang].french_title}
                                onChange={(e) => setFormData({...formData, title_fr: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].german_title}`}
                                name ={`title_de`}
                                type="text"
                                bsSize="lg"
                                value={formData.title_de}
                                placeholder={Translate[lang].german_title}
                                onChange={(e) => setFormData({...formData, title_de: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2"></Col>
                        </>}
                        {type?.value === 'pic_me' && <>
                            <Col md={6} className="form-group mb-3">
                                <label className="d-block">{Translate[lang].english_description}*</label>
                                <div className="editorField">
                                    <Editor
                                        // editorState ={editorState}
                                        editorState ={formData.description_en}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setFormData({...formData, description_en: e})}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="form-group mb-3">
                                <label className="d-block">{Translate[lang].arabic_description}*</label>
                                <div className="editorField">
                                    <Editor
                                        editorState ={formData.description_ar}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setFormData({...formData, description_ar: e})}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="form-group mb-3">
                                <label className="d-block">{Translate[lang].spanish_description}</label>
                                <div className="editorField">
                                    <Editor
                                        editorState ={formData.description_es}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setFormData({...formData, description_es: e})}                                    
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="form-group mb-3">
                                <label className="d-block">{Translate[lang].italian_description}</label>
                                <div className="editorField">
                                    <Editor
                                        editorState ={formData.description_it}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setFormData({...formData, description_it: e})}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="form-group mb-3">
                                <label className="d-block">{Translate[lang].russian_description}</label>
                                <div className="editorField">
                                    <Editor
                                        editorState ={formData.description_ru}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setFormData({...formData, description_ru: e})}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="form-group mb-3">
                                <label className="d-block">{Translate[lang].french_description}</label>
                                <div className="editorField">
                                    <Editor
                                        editorState ={formData.description_fr}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setFormData({...formData, description_fr: e})}
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="form-group mb-4">
                                <label className="d-block">{Translate[lang].german_description}</label>
                                <div className="editorField">
                                    <Editor
                                        editorState ={formData.description_de}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => setFormData({...formData, description_de: e})}
                                    />
                                </div>
                            </Col>
                            <Col md={6}></Col>
                        </>}
                        
                        {type?.value === 'global' && <Col md={12} className="mb-3">
                            <AvField
                                label={Translate[lang]?.link}
                                type='text'
                                placeholder={Translate[lang]?.link}
                                bsSize="lg"
                                name='link'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.link}
                                onChange={(e) => setFormData({...formData, link: e.target.value})}
                            />
                        </Col>}

                        {!!type?.value && <Col md={type?.value === 'video' ? 12 : 6} className="mt-3">
                            <label>{type?.value === 'video' ? Translate[lang].video : Translate[lang].image}</label>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            accept={type?.value === 'video' ? "video/*" : "image/*"}
                                            className="w-100 h-100 cursor-pointer"  
                                            onChange={(e) => fileHandler(e)} /> 					
                                </div>
                                {!!formData.image?.src && <button 
                                    className="delete-image" 
                                    type="button"
                                    onClick={()=> {
                                        setFormData({...formData, image: {src: '', loading: false}})
                                    }}>
                                        <i className="la la-trash text-danger"></i>
                                </button>}
                                    <div className="avatar-preview m-auto" style={{zIndex: !!formData.image?.src ? '8' : ''}}>
                                        <div>
                                            {(!!formData.image?.src && !formData?.image?.loading) && 
                                            (type?.value === 'video' ? <video style={{width: '100%', height: '100%'}} controls>
                                                <source src={formData.image?.src} type="video/mp4" />
                                                <source src={formData.image?.src} type="video/ogg" />
                                            </video> : <img src={formData.image?.src} alt='icon' />) }

                                            {(!formData.image?.src && !formData?.image?.loading) && 
                                            <img src={uploadImg} alt='icon'
                                                style={{ width: '80px', height: '80px' }}
                                            />}

                                            {formData?.image?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>}
                    </Row>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <Button onClick={() => navigate('/blogs')} variant="danger light">
                {Translate[lang]?.close}
                </Button>
                <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}</Button>
            </div>
            </AvForm>
        </Card.Body>
        </Card>)
}

export default AddBlogs;