import { Button, Card, Col, Row } from "react-bootstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import uploadImg from "../../../../../images/upload-img.png";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';
import '../style.scss'
import StaticPagesServices from "../../../../../services/StaticPagesService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../../Enums/Tranlate";
import Loader from "../../../../common/Loader";
import BaseService from "../../../../../services/BaseService";

const Static = () =>{
    const initial = {
        details: [{
            title_en:'',
            title_ar:'',
            title_es: '',
            title_it: '',
            title_ru: '',
            title_fr: '',
            title_de: '',
            image: {src: '', loading: false},
            link: "",
        }],
        title_en:'',
        title_ar:'',
        title_es: '',
        title_it: '',
        title_ru: '',
        title_fr: '',
        title_de: '',
        description_ar: EditorState.createEmpty(),
        description_en: EditorState.createEmpty(),
        description_es: EditorState.createEmpty(),
        description_it: EditorState.createEmpty(),
        description_ru: EditorState.createEmpty(),
        description_fr: EditorState.createEmpty(),
        description_de: EditorState.createEmpty(),
        image: {src: '', loading: false},
        job: "",
        show: false
    }
    const initialCustom ={
        description_ar: EditorState.createEmpty(),
        description_en: EditorState.createEmpty(),
        description_es: EditorState.createEmpty(),
        description_it: EditorState.createEmpty(),
        description_ru: EditorState.createEmpty(),
        description_fr: EditorState.createEmpty(),
        description_de: EditorState.createEmpty(),
        image: {src: '', loading: false},
    }
    const [addMoreData, setAddMoreData] =useState(false)
    const [formData, setFormData] =useState([initial])
    const [customData, setCustomData] =useState([initialCustom])
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const [loading, setLoading] = useState()
    const [submitLoading, setSubmitLoading] = useState(false)
    const staticPagesServices = new StaticPagesServices()

    const changeInput = (e,name,index) =>{
        let update = formData.map((item,ind)=>{
            if(index === ind){
                return{
                    ...item,
                    [name]: e.target.value
                }
            } else{
                return item
            }
        })
        setFormData(update)
    }

    useEffect(()=>{
        let params = {
            type: 'about'
        }
        setLoading(true)
        staticPagesServices.getList(params).then(res=>{
            if(res?.status === 200){
                if(res.data.data?.length > 0){
                    let data = res.data.data?.filter(res=> !!res?.title_en && !!res?.job)?.map(item =>{
                        return{
                            title_en: item.title_en,
                            title_ar: item.title_ar,
                            title_es: item?.title_es,
                            title_it: item?.title_it,
                            title_ru: item?.title_ru,
                            title_fr: item?.title_fr,
                            title_de: item?.title_de,
                            show: !!item?.details?.length,
                            
                            job: item?.job,
                            description_ar: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_ar))),
                            description_en: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_en))),
                            description_es: !!item.description_es ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_es))) : '',
                            description_it: !!item.description_it ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_it))) : '',
                            description_ru: !!item.description_ru ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_ru))) : '',
                            description_de: !!item.description_de ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_de))) : '',
                            description_fr: !!item.description_fr ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_fr))) : '',
                
                            image: {src: item?.image, loading: false},
                            details: !!item?.details?.length ? item?.details?.map(details => {
                                return {
                                    title_en: details?.title_en || '',
                                    title_ar: details?.title_ar || '',
                                    title_es: details?.title_es || '',
                                    title_it: details?.title_it || '',
                                    title_ru: details?.title_ru || '',
                                    title_fr: details?.title_fr || '',
                                    title_de: details?.title_de || '',
                                    image: {src: details?.image, loading: false},
                                    link: details?.link
                                }
                            }) : [{
                                title_en: '', title_ar: '', title_es: '', title_it: '', title_ru: '', title_fr: '', title_de: '', image: {src: "", loading: false}, link: ""
                            }],
                        }
                    })
                    let custom = res.data.data?.filter(res=> !res?.title_en && !res?.job)?.map(item =>{
                        return{
                            title_en: item.title_en,
                            title_ar: item.title_ar,
                            title_es: item?.title_es,
                            title_it: item?.title_it,
                            title_ru: item?.title_ru,
                            title_fr: item?.title_fr,
                            title_de: item?.title_de,
                            show: (!!item?.second_title_en || !!item?.second_title_ar || !!item?.links?.map(link => link?.url)?.length || !!item?.second_image) ,
                            second_title_en: item?.second_title_en || '',
                            second_title_ar: item?.second_title_ar || '',
                            second_title_es: item?.second_title_es || '',
                            second_title_it: item?.second_title_it || '',
                            second_title_ru: item?.second_title_ru || '',
                            second_title_fr: item?.second_title_fr || '',
                            second_title_de: item?.second_title_de || '',
                            job: item?.job,
                            description_ar: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_ar))),
                            description_en: EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_en))),
                            description_es: !!item.description_es ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_es))) : '',
                            description_it: !!item.description_it ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_it))) : '',
                            description_ru: !!item.description_ru ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_ru))) : '',
                            description_de: !!item.description_de ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_de))) : '',
                            description_fr: !!item.description_fr ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(item.description_fr))) : '',
                
                            image: {src: item?.image, loading: false},
                            second_image: {src: item?.second_image, loading: false},
                            links: item?.links?.map(link => link?.url) || ['']
                        }
                    })
                    if(custom?.length > 0){
                        setCustomData(custom)
                    }
                    if(data?.length > 0){
                        setFormData(data)
                    }
                }
            }
            setLoading(false)
        })
    },[])

    const fileHandler = (e, index) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        let update = formData?.map((res, ind) => {
                if(ind === index){
                    return {
                        ...res,
                        image: {src: '', loading: true}
                    }
                } else {
                    return res
                }
        })
        
        setFormData(update)

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.status) {
            let update = formData?.map((info, ind) => {
                    if(ind === index){
                        return {
                            ...info,
                            image: {src: res.data.url, loading: false}
                        }
                    } else {
                        return info
                    }
            })

            setFormData(update)
          }
        });
    };

    const fileDetailsHandler = (e, index, ind) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        let update = formData?.map((res, resInd) => {
                if(resInd === index){
                    return {
                        ...res,
                        details: res?.details?.map((det, detInd) => {
                            if(detInd === ind){
                                return {
                                    ...det,
                                    image: {src: '', loading: true}
                                }
                            } else {
                                return det
                            }
                        })
                    }
                } else {
                    return res
                }
        })
        setFormData(update)

        new BaseService().postUpload(filesData[0]).then((response) => {
          if (response?.status) {
            let update = formData?.map((res, resInd) => {
                if(resInd === index){
                    return {
                        ...res,
                        details: res?.details?.map((det, detInd) => {
                            if(detInd === ind){
                                return {
                                    ...det,
                                    image: {src: response?.data?.url, loading: false}
                                }
                            } else {
                                return det
                            }
                        })
                    }
                } else {
                    return res
                }
        })
        setFormData(update)
          }
        });
    };

    const fileCustomHandler = (e) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        let update = customData?.map(res=>{
            return {
                ...res,
                image: {src: '', loading: true}
            }
        })
        setCustomData(update)

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.status) {
            let update = customData?.map(res2=>{
                return {
                    ...res2,
                    image: {src: res.data.url, loading: false}
                }
            })
            setCustomData(update)
          }
        });
    };

    const submit = () =>{
        let data ={
            type: 'about',
            static_page: [...formData, ...customData].map(res=>{
                let en = draftToHtml(convertToRaw(res.description_en.getCurrentContent()))
                let info = {
                    description_en: en,
                    image: res?.image?.src,
                }
                if(!!res?.title_en) info['title_en'] = res?.title_en;
                if(!!res?.title_ar) info['title_ar'] = res?.title_ar;
                if(!!res?.title_es) info['title_es'] = res?.title_es;
                if(!!res?.title_it) info['title_it'] = res?.title_it;
                if(!!res?.title_ru) info['title_ru'] = res?.title_ru;
                if(!!res?.title_fr) info['title_fr'] = res?.title_fr;
                if(!!res?.title_de) info['title_de'] = res?.title_de;

                if(!!res?.job) info['job'] = res?.job;
            
                if(!!res?.description_ar) info['description_ar'] = draftToHtml(convertToRaw(res.description_ar.getCurrentContent()));
                if(!!res?.description_es) info['description_es'] = draftToHtml(convertToRaw(res.description_es.getCurrentContent()));
                if(!!res?.description_it) info['description_it'] = draftToHtml(convertToRaw(res.description_it.getCurrentContent()));
                if(!!res?.description_ru) info['description_ru'] = draftToHtml(convertToRaw(res.description_ru.getCurrentContent()));
                if(!!res?.description_fr) info['description_fr'] = draftToHtml(convertToRaw(res.description_fr.getCurrentContent()));
                if(!!res?.description_de) info['description_de'] = draftToHtml(convertToRaw(res.description_de.getCurrentContent()));
                if(!!res?.description_de) info['description_de'] = draftToHtml(convertToRaw(res.description_de.getCurrentContent()));
                
                if(!!res?.details?.length) info['details'] = res?.details?.filter(det=> !!det?.link || !!det?.image?.src || !!det?.title_en || !!det?.title_ar)?.map(det => {
                    let detailsObj = {}
                    if(!!det?.image?.src) detailsObj['image'] = det?.image?.src;
                    if(!!det?.title_ar) detailsObj['title_ar'] = det?.title_ar;
                    if(!!det?.title_en) detailsObj['title_en'] = det?.title_en;
                    if(!!det?.title_es) detailsObj['title_es'] = det?.title_es;
                    if(!!det?.title_it) detailsObj['title_it'] = det?.title_it;
                    if(!!det?.title_ru) detailsObj['title_ru'] = det?.title_ru;
                    if(!!det?.title_fr) detailsObj['title_fr'] = det?.title_fr;
                    if(!!det?.title_de) detailsObj['title_de'] = det?.title_de;
                    if(!!det?.link) detailsObj['link'] = det?.link;
                    
                    return detailsObj
                })
            
                return info
            })
        }
        setSubmitLoading(true)
        staticPagesServices.create(data).then(res=>{
            if(res?.status === 201){
                toast.success("Update Data Successfullly")
            }
            setSubmitLoading(false)
        })
    }

    const handleDetails = (e, name, formDataIndex, detailIndex) => {
        let update = formData?.map((formD, formIndex)=>{
            if(formIndex === formDataIndex){
                return {
                    ...formD,
                    details: formD?.details?.map((det, detInd) =>{
                        if(detailIndex === detInd) {
                            return {
                                ...det,
                                [name]: e?.target?.value
                            }
                        } else {
                            return det
                        }
                    })
                }
            } else {
                return formD
            }
        })
        setFormData(update)
    }

    if(loading){
        return <Card className="py-5" style={{height: '300px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
      </Card>
    }

    return<>
        <AvForm onValidSubmit={submit}>
            {formData?.map((item, index)=>{
                return <Card>
                <Card.Body>
                <Row className="mb-5 position-relative" key={index}>
                    {index > 0 && <button className="delete border-0"  type="button" onClick={()=>{
                        let update = formData.filter((_,ind) => ind !== index)
                        setFormData(update)
                    }}>
                        <i className="la la-times"></i>
                    </button>}
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].english_title}*`}
						name ={`title_en${index}`}
						type="text" 
						value={item.title_en}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={Translate[lang].english_title}
						onChange={(e) => changeInput(e,'title_en',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].arabic_title}`}
					    name ={`title_ar${index}`}
						type="text"
						value={item.title_ar}
						// validate={{
						// 	required: {
						// 		value:true,
						// 		errorMessage: Translate[lang].field_required
						// 	},
						// }}
						placeholder={Translate[lang].arabic_title}
						onChange={(e) => changeInput(e,'title_ar',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].spanish_title}`}
					    name ={`title_es${index}`}
						type="text"
						value={item.title_es}
						placeholder={Translate[lang].spanish_title}
						onChange={(e) => changeInput(e,'title_es',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].italian_title}`}
					    name ={`title_it${index}`}
						type="text"
						value={item.title_it}
						placeholder={Translate[lang].italian_title}
						onChange={(e) => changeInput(e,'title_it',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].russian_title}`}
					    name ={`title_ru${index}`}
						type="text"
						value={item.title_ru}
						placeholder={Translate[lang].russian_title}
						onChange={(e) => changeInput(e,'title_ru',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].french_title}`}
					    name ={`title_fr${index}`}
						type="text"
						value={item.title_fr}
						placeholder={Translate[lang].french_title}
						onChange={(e) => changeInput(e,'title_fr',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].german_title}`}
					    name ={`title_de${index}`}
						type="text"
						value={item.title_de}
						placeholder={Translate[lang].german_title}
						onChange={(e) => changeInput(e,'title_de',index)}
					/>
				</Col>
                <Col md={6} className="form-group mb-2"></Col>

                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].english_description}*</label>
                    <div className="editorField">
                        <Editor
                            // editorState ={editorState}
                            editorState ={item.description_en}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                // if(isEdit){
                                //     return
                                // }
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_en: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].arabic_description}*</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_ar}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_ar: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].spanish_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_es}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_es: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].italian_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_it}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_it: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].russian_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_ru}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_ru: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].french_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_fr}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_fr: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-4">
                    <label className="d-block">{Translate[lang].german_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_de}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = formData.map((item,ind)=>{
                                    if(index === ind){
                                        return{
                                            ...item,
                                            description_de: e
                                        }
                                    } else{
                                        return item
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                    </div>
				</Col>
                <Col md={6}></Col>

                <Col md={6}>
                            <label>{Translate[lang].image}*</label>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            accept="image/*"
                                            className="w-100 h-100 cursor-pointer"  
                                            onChange={(e) => fileHandler(e, index)} /> 					
                                    </div>
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.image?.src && !item?.image?.loading) && <img src={item.image?.src} alt='icon' />}
                                            {(!item.image?.src && !item?.image?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.image?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                </Col>
                <Col md={6}></Col>
                <Col md={6} className="form-group mb-2">
					<AvField
						label ={`${Translate[lang].job}*`}
						name ={`job${index}`}
						type="text" 
						value={item.job}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={Translate[lang].job}
						onChange={(e) => changeInput(e,'job',index)}
					/>
				</Col>
                <Col md={12} className="mt-4 mb-3">
                    <Button 
                        variant="outline-primary"
                        onClick={()=> {
                            let update = formData?.map((res, inde) => {
                                if(inde === index){
                                    return {
                                        ...res,
                                        show: true
                                    }
                                } else {
                                    return res
                                }
                            })
                            setFormData(update)
                        }}
                    >
                        {Translate[lang].add_more_data}
                    </Button>
                </Col>

                {item?.show && <> {item?.details?.map((detail, ind) => {
                    return <Card className="px-3 py-4 position-relative" key={ind} style={{border: '1px solid #dedede', marginTop:'2rem'}}>
                        <button className="delete border-0" style={{top: '5px'}}  type="button" onClick={()=>{
                            let update = formData?.map((foDa, foIndex)=>{
                                let filterData = foDa?.details?.filter((_,deInd) => ind !== deInd)
                                if(foIndex === index){
                                    return{
                                        ...foDa,
                                        details: filterData,
                                        show: !!filterData?.length
                                    }
                                } else {
                                    return foDa
                                }
                            })
                            setFormData(update)
                        }}>
                        <i className="la la-times"></i>
                        </button>
                        <Row>
                            <Col md={6} className="form-group mb-2">
                                <AvField
                                    label ={`${Translate[lang].english_title}`}
                                    name ={`title_en${index}_${ind}`}
                                    type="text" 
                                    value={detail.title_en}
                                    placeholder={Translate[lang].english_title}
                                    onChange={(e) => handleDetails(e, 'title_en', index, ind)}
                                />
                                </Col>
                                <Col md={6} className="form-group mb-2">
                                    <AvField
                                        label ={`${Translate[lang].arabic_title}`}
                                        name ={`title_ar${index}_${ind}`}
                                        type="text"
                                        value={detail.title_ar}
                                        placeholder={Translate[lang].arabic_title}
                                        onChange={(e) => handleDetails(e, 'title_ar', index, ind)}
                                    />
                                </Col>
                                <Col md={6} className="form-group mb-2">
                                    <AvField
                                        label ={`${Translate[lang].spanish_title}`}
                                        name ={`title_es${index}_${ind}`}
                                        type="text"
                                        value={detail?.title_es}
                                        placeholder={Translate[lang].spanish_title}
                                        onChange={(e) => handleDetails(e, 'title_es', index, ind)}
                                    />
                                </Col>
                                <Col md={6} className="form-group mb-2">
                                    <AvField
                                        label ={`${Translate[lang].italian_title}`}
                                        name ={`title_it${index}_${ind}`}
                                        type="text"
                                        value={detail.title_it}
                                        placeholder={Translate[lang].italian_title}
                                        onChange={(e) => handleDetails(e, 'title_it', index, ind)}
                                    />
                                </Col>
                                <Col md={6} className="form-group mb-2">
                                    <AvField
                                        label ={`${Translate[lang].russian_title}`}
                                        name ={`title_ru${index}_${ind}`}
                                        type="text"
                                        value={detail.title_ru}
                                        placeholder={Translate[lang].russian_title}
                                        onChange={(e) => handleDetails(e, 'title_ru', index, ind)}
                                    />
                                </Col>
                                <Col md={6} className="form-group mb-2">
                                    <AvField
                                        label ={`${Translate[lang].french_title}`}
                                        name ={`title_fr${index}_${ind}`}
                                        type="text"
                                        value={detail.title_fr}
                                        placeholder={Translate[lang].french_title}
                                        onChange={(e) => handleDetails(e, 'title_fr', index, ind)}
                                    />
                                </Col>
                                <Col md={6} className="form-group mb-2">
                                    <AvField
                                        label ={`${Translate[lang].german_title}`}
                                        name ={`title_de${index}`}
                                        type="text"
                                        value={detail.title_de}
                                        placeholder={Translate[lang].german_title}
                                        onChange={(e) => handleDetails(e, 'title_de', index, ind)}
                                    />
                                </Col>
                                <Col md={12} className="form-group mb-2">
                                    <AvField
                                        label ={`${Translate[lang].link}`}
                                        name ={`link${index}`}
                                        type="text"
                                        value={detail?.link}
                                        placeholder={Translate[lang].link}
                                        onChange={(e) => handleDetails(e, 'link', index, ind)}
                                    />
                                </Col>
                                <Col md={12}>
                                <label>{Translate[lang].image}</label>
                                <div className="image-placeholder">	
                                    <div className="avatar-edit w-100 h-100">
                                            <input 
                                                type="file" 
                                                // accept="image/*"
                                                className="w-100 h-100 cursor-pointer"  
                                                onChange={(e) => fileDetailsHandler(e, index, ind)} /> 					
                                        </div>
                                        <div className="avatar-preview m-auto">
                                            <div>
                                                {(!!detail.image?.src && !detail?.image?.loading) && 
                                                ((detail.image?.src.includes('.mp4') || detail.image?.src.includes('.m4v') || detail.image?.src.includes('.m4p')) ? <video style={{width: '90%', height: '85%', zIndex: '99'}} controls>
                                                    <source src={detail.image?.src} type="video/mp4" />
                                                    <source src={detail.image?.src} type="video/ogg" />
                                                </video> : <img src={detail.image?.src} alt='icon' />)}
                                                {(!detail.image?.src && !detail?.image?.loading) && 
                                                <img  
                                                    src={uploadImg} alt='icon'
                                                    style={{
                                                        width: '80px', height: '80px',
                                                    }}
                                                />}
                                                {detail?.image?.loading && <Loader />}
                                            </div>
                                    </div>
                                </div>
                                </Col>
                        </Row>
                    </Card>
                })}
                </>}
                {item?.show && <Col md={12}>
                    <Button 
                        variant="outline-primary"
                        onClick={()=> {
                            let update = formData?.map((formD, formIndex) => {
                                if(index === formIndex){
                                    return { 
                                        ...formD, 
                                        details: [...formD.details, {
                                            title_en: '', title_ar: '', title_es: '', title_it: '', title_ru: '', title_fr: '', title_de: '', image: {src: "", loading: false}, link: ""
                                        }]
                                    }
                                } else {
                                    return formD
                                }
                            })
                            setFormData(update)
                        }}
                    >
                        {Translate[lang].add} {Translate[lang].details}
                    </Button>
                </Col>}
            </Row>
            </Card.Body>
            </Card>
            })}

            <div className="mt-4">
            {customData?.map((item, index)=>{
                return <Card>
                <Card.Body>
                <Row className="mb-5 position-relative" key={index}>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].english_description}</label>
                    <div className="editorField">
                        <Editor
                            // editorState ={editorState}
                            editorState ={item.description_en}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = {
                                    ...item,
                                    description_en: e
                                }
                                setCustomData([update])
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].arabic_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_ar}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = {
                                    ...item,
                                    description_ar: e
                                }
                                setCustomData([update])
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].spanish_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_es}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = {
                                    ...item,
                                    description_es: e
                                }
                                setCustomData([update])
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].italian_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_it}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = {
                                    ...item,
                                    description_it: e
                                }
                                setCustomData([update])
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].russian_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_ru}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = {
                                    ...item,
                                    description_ru: e
                                }
                                setCustomData([update])
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-3">
                    <label className="d-block">{Translate[lang].french_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_fr}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = {
                                    ...item,
                                    description_fr: e
                                }
                                setCustomData([update])
                            }}
                        />
                    </div>
				</Col>
                <Col md={6} className="form-group mb-4">
                    <label className="d-block">{Translate[lang].german_description}</label>
                    <div className="editorField">
                        <Editor
                            editorState ={item.description_de}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => {
                                let update = {
                                    ...item,
                                    description_de: e
                                }
                                setCustomData([update])
                            }}
                        />
                    </div>
				</Col>
                <Col md={6}></Col>

                <Col md={12}>
                            <label>{Translate[lang].image}</label>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            accept="image/*"
                                            className="w-100 h-100 cursor-pointer"  
                                            onChange={(e) => fileCustomHandler(e)} /> 					
                                    </div>
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.image?.src && !item?.image?.loading) && <img src={item.image?.src} alt='icon' />}
                                            {(!item.image?.src && !item?.image?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.image?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                </Col>
            </Row>
            </Card.Body>
            </Card>
            })}
            </div>
            {isExist('static_pages') && <div className="d-flex justify-content-between mt-3 mb-5">
                <Button
                    variant="secondary" 
                    onClick={()=>{
                    setFormData([...formData, initial])
                }}>
                    {Translate[lang].add_new_value}
                </Button>
                <Button variant="primary" disabled={submitLoading} type="submit">
                    {Translate[lang].submit}
                </Button>
                {/* {isEdit && <Button variant="primary" type="button" onClick={()=>setIsEdit(false)}>
                    {Translate[lang].edit}
                </Button>} */}
            </div>}
        </AvForm>
    </>
}
export default Static;