import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import Pagination from "../../common/Pagination/Pagination";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import './style.scss'
import { Link } from "react-router-dom";
import ServicesService from "../../../services/ServicesService";

const Services = () => {
    const [data, setData] = useState([
      {id: 1, image: 'https://back.cloudliftsolution.com/125422(1)-1715698140766.jpg', title: "Elevate your Brand with Cloud Lift Solutions: Pioneering Creative Excellence in the GCC"},
      {id: 2, image: 'https://back.cloudliftsolution.com/125422(1)-1715698140766.jpg', title: "Elevate your Brand with Cloud Lift Solutions: Pioneering Creative Excellence in the GCC"},
      {id: 3, image: 'https://back.cloudliftsolution.com/125422(1)-1715698140766.jpg', title: "Elevate your Brand with Cloud Lift Solutions: Pioneering Creative Excellence in the GCC"},
    ])
    const [item, setItem] = useState({})
    const [hasData, setHasData] = useState(null)
    const [search, setSearch] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const Auth = useSelector((state) => state.auth?.auth);
    const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
    const servicesService = new ServicesService()

    useEffect(()=> {
      setLoading(true)
      servicesService?.getList().then(res=>{
        if(res?.status === 200){
          setData([...res.data?.data]);
          if (res.data?.data?.length > 0) {
            setHasData(1);
          } else {
            setHasData(0);
          }
        }
        setLoading(false);
      })
    },[shouldUpdate])

  return (
    <div className="department">
      <Card className="mb-3">
        <Card.Body className="search-card d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-50">
            <input 
                type="text" 
                style={{borderRadius: '8px',
                color: 'initial',
                padding: '18px 16px'}}
                className="form-control"
                placeholder={`${Translate[lang]?.search_by} I.D, ${Translate[lang]?.name}`}
                value={search}
                onChange={e=> setSearch(e.target.value)} 
            />
            <div className="flaticon-381-search-2"
              style={{position: 'absolute',zIndex:'1', right: lang === 'en' && '16px', left: lang === 'ar' && '16px', top: '50%', transform: 'translate(0, -50%)'}}
            ></div>
          </div>
          <div>
            {isExist('services') && <Link to='/services/add-services' variant="primary" className='btn btn-primary h-75'>
              <i className="la la-plus mx-1"></i>
              {Translate[lang]?.add} {Translate[lang]?.services}
            </Link>}
          </div>
        </Card.Body >
      </Card>
      
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body className={`${hasData === 0 && 'text-center'} `}>
            {loading && <div style={{height: '300px'}}>
                <Loader />
              </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.image}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.title}</strong>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="table-body">
                    {data?.map((item, index) =>{
                        return <CardItem 
                            index= {index}
                            key= {index}
                            item={item}
                            setItem={setItem}
                            setShouldUpdate={setShouldUpdate}
                        />
                    })}
                </tbody>
              </Table>}
              {hasData === 0 && <NoData />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Services;
