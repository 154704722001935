import BaseService from "./BaseService";
import http from "./HttpService";
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/blog";
const apiVideoEndpoint = API_BASE_URL_ENV() + "/blog/blogVideo";

export default class BlogsService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  addBlogVideo(data){
    return http.post(apiVideoEndpoint, data)
  }

  getBlogVideo(){
    return http.get(apiVideoEndpoint)
  }
}
