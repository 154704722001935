import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/appointment/allBooksForAdmin";

export default class AppointmentService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    bookingDay(date){
        return http.get(`${API_BASE_URL_ENV()}/appointment/getBookDayForAdmin?date=${date}`)
    }
}
