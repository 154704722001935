import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Translate } from "../../Enums/Tranlate";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import uploadImg from '../../../images/upload-img.png'
import BaseService from "../../../services/BaseService";
import './style.css'
import HomeService from "../../../services/HomeService";
import { toast } from "react-toastify";

const Home = () => {
    const [data, setData] = useState([
        {one: {src: '', loading: false}}, //two: {src: '', loading: false}, three: {src: '', loading: false}},
        {one: {src: '', loading: false}}, //two: {src: '', loading: false}, three: {src: '', loading: false}},
        {one: {src: '', loading: false}}, //two: {src: '', loading: false}, three: {src: '', loading: false}},
    ])
    const lang = useSelector((state) => state.auth?.lang);
    const [loading, setLoading] = useState(false)
    const homeService = new HomeService()

    useEffect(()=>{
        homeService.getList().then(res=>{
            if(res?.status){
                let response = []
                if(res?.data?.data?.length > 3){
                    response = res?.data?.data?.map(info => {
                    return {
                        one: {src: info?.one, loading: false } //two: {src: info?.two, loading: false }, three: {src: info?.three, loading: false }
                    }
                  })
                } else {
                    response = Array.from({ length: 3 }, (_, index) => {
                    let arr = res?.data?.data;
                    if(arr[index]){
                      return {
                        one: {src: arr[index]?.one, loading: false }//, two: {src: arr[index]?.two, loading: false }, three: {src: arr[index]?.three, loading: false }
                        }
                    } else {
                      return {
                        one: {src: "", loading: false }//, two: {src: "", loading: false }, three: {src: "", loading: false }
                        }
                    }
                  })
                }
                setData(response)
            }
        })
    },[])

    const fileHandler = (e, index, type) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        let update = data?.map((item, itemIndex) => {
            if(itemIndex === index){
                return {
                    ...item,
                    [type]: {src: '', loading: true}
                }
            } else {
                return item
            }
        })
        setData(update)

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.status) {
              let update = data?.map((item, itemIndex) => {
                if(itemIndex === index){
                    return {
                        ...item,
                        [type]: {src: res.data.url, loading: false}
                    }
                } else {
                    return item
                }
            })
            setData(update)
          } else {
            let update = data?.map((item, itemIndex) => {
                if(itemIndex === index){
                    return {
                        ...item,
                        [type]: {src: '', loading: false}
                    }
                } else {
                    return item
                }
            })
            setData(update)
          }
        }).catch(()=> {});
    };

    const onSubmit = () => {
        let body = {
            home_pic: data?.filter(res=> !!res?.one?.src)?.map(res=>{
                return {
                    one: res?.one?.src,
                    // two: res?.two?.src,
                    // three: res?.three?.src,
                }
            })
        }
        setLoading(true)
        homeService.create(body).then(res=>{
            if(res?.status){
                toast.success("Home Images Updated Successfully.")
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    }

    if(loading){
        return <div style={{minHeight: '500px'}}>
        <Loader />
        </div>
    }

    return <>
        <Card>
            <Card.Body>
                {data?.map((item,index) => {
                    return <Row key={index}>
                        <Col md={12}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                    <input type="file" className="w-100 h-100 cursor-pointer"  onChange={(e) => fileHandler(e, index, 'one')}/> 					
                                </div>
                                {!!item.one?.src && <button 
                                    className="delete-image"
                                    type="button"
                                    onClick={()=>{
                                        let update= data?.map((item, ind)=>{
                                            if(ind === index){
                                                return{
                                                    ...item,
                                                    one: { src: "", loading: false}
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        setData([...update])
                                    }}
                                >
                                    <i className="la la-trash text-danger"></i>
                                </button>}
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.one?.src && !item?.one?.loading) && <img src={item.one?.src} alt='icon' />}
                                            {(!item.one?.src && !item?.one?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.one?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={6}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            className="w-100 h-100 cursor-pointer" 
                                            onChange={(e) => fileHandler(e, index, 'two')}
                                        /> 					
                                    </div>
                                    {!!item.two?.src && <button 
                                    className="delete-image"
                                    type="button"
                                    onClick={()=>{
                                        let update= data?.map((item, ind)=>{
                                            if(ind === index){
                                                return{
                                                    ...item,
                                                    two: { src: "", loading: false}
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        setData([...update])
                                    }}
                                >
                                    <i className="la la-trash text-danger"></i>
                                </button>}
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.two?.src && !item?.two?.loading) && <img src={item.two?.src} alt='icon' />}
                                            {(!item.two?.src && !item?.two?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.two?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            className="w-100 h-100 cursor-pointer"  
                                            onChange={(e) => fileHandler(e, index, 'three')} /> 					
                                    </div>
                                    {!!item.three?.src && <button 
                                    className="delete-image"
                                    type="button"
                                    onClick={()=>{
                                        let update= data?.map((item, ind)=>{
                                            if(ind === index){
                                                return{
                                                    ...item,
                                                    three: { src: "", loading: false}
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        setData([...update])
                                    }}
                                >
                                    <i className="la la-trash text-danger"></i>
                                </button>}
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.three?.src && !item?.three?.loading) && <img src={item.three?.src} alt='icon' />}
                                            {(!item.three?.src && !item?.three?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.three?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                })}
                <div className="d-flex justify-content-between mt-5">
                    <Button 
                        variant='outline-primary'
                        onClick={() => setData([...data, {one: {src: '', loading: false}, two: {src: '', loading: false}, three: {src: '', loading: false}}])}
                    >{Translate[lang].add_new_value}</Button>
                    <Button variant='primary' disabled={loading} onClick={onSubmit}>{Translate[lang].submit}</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}
export default Home;