import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import Pagination from "./Pagination/Pagination";
import { Translate } from "../../Enums/Tranlate";
import AppointmentService from "../../../services/AppointmentService";

const Appointment = () => {
  const [data, setData] = useState(null);
  const [hasData, setHasData] = useState(null);
  const [date, setDate] = useState('');
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const appointmentService = new AppointmentService();
  const lang = useSelector((state) => state.auth?.lang);
  const times = [
    {from: '5:00', to: '6:00', value: 1},
    {from: '6:15', to: '7:15', value: 2},
    {from: '7:30', to: '8:30', value: 3},
    {from: '8:45', to: '9:45', value: 4},
    {from: '10:00', to: '11:00', value: 5}
  ]

  useEffect(()=>{
    if(!!date){
        setLoading(true)
        appointmentService.bookingDay(date).then(res => {
            if(res?.status){
                setData([res?.data?.data])
                if (!!res.data?.data?.id) {
                    setHasData(1);
                } else {
                    setHasData(0);
                }
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    } else {
        setShouldUpdate(prev => !prev)
    }
  }, [date])

  return (
    <div>
      <Card className="mb-3">
        <Card.Body className="d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-50">
            <input
              type="date"
              style={{
                borderRadius: "8px",
                color: "initial",
                padding: "18px 16px",
              }}
              className="form-control"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div></div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body className={`${hasData === 0 ? "text-center" : ""}`}>
          {loading && (
            <div style={{ height: "300px" }}>
              <Loader />
            </div>
          )}
          {hasData === 1 && !loading &&
            data?.map((item, index) => {
                return <>
                <Row key={index}>
                    <Col md={12} className="mb-2">
                        <ul style={{display: "list-item"}}>
                            <li>
                                <label>{Translate[lang].date}: <span style={{color: '#111'}}>{item?.date?.split('T')[0]}</span></label>
                            </li>
                        </ul>
                    </Col>
                    {item?.appointment_nums?.map((appNum, ind) => {
                        return <Col md={3} key={ind}>
                            <div className="h-100" style={{
                                backgroundColor: 'rgb(222 222 222 / 40%)',
                                padding: '8px',
                                borderRadius: '6px',
                            }}>
                                <h5 className="text-primary">
                                    {appNum?.name}
                                </h5>
                                <h5 className="text-primary">
                                    {appNum?.phone}
                                </h5>
                                {(!!appNum?.name || !!appNum?.phone) && <hr className="mt-2 mb-2" />}
                                {appNum?.num === 6 ?
                                <h5 style={{color: '#e04d4d'}}>
                                    {Translate[lang].full_day}
                                </h5>
                                : <>
                                <h5>
                                    {Translate[lang].from} {'  '} <span style={{color: '#e04d4d'}}>{times?.find(res=> res?.value === appNum?.num)?.from}</span>
                                </h5>
                                <h5>
                                    {Translate[lang].to} {'  '} <span style={{color: '#e04d4d'}}>{times?.find(res=> res?.value === appNum?.num)?.to}</span>
                                </h5>
                                </>}
                                <hr className="mt-2 mb-2" />
                                <h5>{appNum?.type}</h5>
                            </div>
                        </Col>
                    })}
                </Row>
                <hr />
                </>
            })
          }
          {hasData === 0 && <NoData />}
          <Pagination
            setData={setData}
            service={appointmentService}
            shouldUpdate={shouldUpdate}
            setHasData={setHasData}
            setLoading={setLoading}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
export default Appointment;
