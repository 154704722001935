import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Translate } from "../../Enums/Tranlate";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import uploadImg from '../../../images/upload-img.png'
import BaseService from "../../../services/BaseService";
import Select from 'react-select';
import GalleryService from "../../../services/GalleryService";
import { toast } from "react-toastify";
// import './style.css'

const Gallery = () => {
    const [data, setData] = useState([
        {one: {src: '', loading: false}, 
        two: {src: '', loading: false}, 
        three: {src: '', loading: false}},
    ])
    const typeOptions = [
        {label: 'Beauty', value: 'beauty'},
        {label: 'Fashion', value: 'fashion'},
        {label: 'Product', value: 'product'},
        {label: 'Life Style', value: 'life_style'},
    ]
    const [type, setType] = useState('')
    const [loading, setLoading] = useState(false)
    const [upload, setUpload] = useState(false)
    const lang = useSelector((state) => state.auth?.lang);
    const galleryService = new GalleryService()

    useEffect(()=> {
        if(!!type?.value){
            let params = {
                type: type?.value
            }
            setLoading(true)
            if(type?.value !== 'fashion'){
                galleryService?.getList(params).then(res=>{
                    if(res?.status === 200){
                        let response = res?.data?.data?.filter((item, index, self) =>
                            index === self.findIndex((t) => parseInt(t?.one.match(/\.com\/(\d+)-/)[1], 10) === parseInt(item?.one.match(/\.com\/(\d+)-/)[1], 10))
                        ).sort((a, b) => {
                            const extractNumber = (url) => parseInt(url.match(/\.com\/(\d+)-/)[1], 10);
                            return extractNumber(a.one) - extractNumber(b.one);
                        })?.map(info => {
                            let info_data={
                                one: {src: info.one, loading: false}
                            }
                            if(!!info.two) info_data['two']= {src: info.two, loading: false}
                            if(!!info.three) info_data['three']= {src: info.three, loading: false}
                            return info_data
                        })
                        if(response?.length > 0) {
                            setData(response)
                        } else {
                            setData([{one: {src: '', loading: false}, two: {src: '', loading: false}, three: {src: '', loading: false}}])
                        }
                    }
                    setLoading(false)
                }).catch(()=> setLoading(false))
            } else {
                galleryService.getFashion().then(res=>{
                    if(res?.status){
                        if(res?.data?.data?.length > 0){
                            let filter = res?.data?.data?.filter((item, index, self) =>
                                index === self.findIndex((t) => parseInt(t.image.match(/\.com\/(\d+)-/)[1], 10) === parseInt(item.image.match(/\.com\/(\d+)-/)[1], 10))
                            )?.sort((a, b) => {
                                const extractNumber = (url) => parseInt(url.match(/\.com\/(\d+)-/)[1], 10);
                                return extractNumber(a.image) - extractNumber(b.image);
                            })?.map(info => {
                                return {
                                    one: {src: info?.image, loading: false}
                                }
                            })
                            setData(filter)
                        } else {
                            setData([{one: {src: '', loading: false}}])
                        }
                    }
                    setLoading(false)
                }).catch(()=> setLoading(false))
            }
        }
    },[type])

    const fileHandler = (e, index, typ) => {
        if(!type){
            toast.error('Select Type')
            return
        }
        if(e.target.files?.length === 0){
          return
        }
        if(!parseInt(e.target?.files[0]?.name)){
            toast.error("Image must named by Numbers")
            return
        }

        setUpload(true)
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        let update = data?.map((item, itemIndex) => {
            if(itemIndex === index){
                return {
                    ...item,
                    [typ]: {src: '', loading: true}
                }
            } else {
                return item
            }
        })
        setData(update)

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.data?.status) {
              let update = data?.map((item, itemIndex) => {
                if(itemIndex === index){
                    return {
                        ...item,
                        [typ]: {src: res.data.url, loading: false}
                    }
                } else {
                    return item
                }
            })
            setData(update)
          } else {
            let update = data?.map((item, itemIndex) => {
                if(itemIndex === index){
                    return {
                        ...item,
                        [typ]: {src: '', loading: false}
                    }
                } else {
                    return item
                }
            })
            setData(update)
          }
          setUpload(false)
        }).catch(()=> setUpload(false));
    };

    const onSubmit = () => {
        if(!type){
            toast.error('Select Type')
            return
        }
        let filter = []
        if(type?.value === 'fashion'){
            filter = data?.filter(res=> !!res?.one?.src).filter((item, index, self) =>
                index === self.findIndex((t) => parseInt(t.one?.src.match(/\.com\/(\d+)-/)[1], 10) === parseInt(item.one?.src.match(/\.com\/(\d+)-/)[1], 10))
            ).sort((a, b) => {
                const extractNumber = (url) => parseInt(url.match(/\.com\/(\d+)-/)[1], 10);
                return extractNumber(a.one?.src) - extractNumber(b.one?.src);
            })?.map(res=>{
                return res?.one?.src
            })
        } else {
            filter = data?.filter(res=> !!res?.one?.src && !!res?.two?.src && !!res?.three?.src).filter((item, index, self) =>
                index === self.findIndex((t) => parseInt(t.one?.src.match(/\.com\/(\d+)-/)[1], 10) === parseInt(item.one?.src.match(/\.com\/(\d+)-/)[1], 10))
            ).sort((a, b) => {
                const extractNumber = (url) => parseInt(url.match(/\.com\/(\d+)-/)[1], 10);
                return extractNumber(a.one?.src) - extractNumber(b.one?.src);
            })?.map(res=>{
                return {
                    one: res?.one?.src,
                    two: res?.two?.src,
                    three: res?.three?.src,
                }
            })
        }
        if(type?.value === 'fashion'){
            let bodyData = {
                images: filter
            }
            galleryService.postFashion(bodyData).then(res=>{
                if(res?.status){
                    toast.success("Gallery Updated Successfully.")
                }
            })
            return
        }
        let body = {
            gallery: filter,
            type: type?.value
        }
        galleryService.create(body).then(res=>{
            if(res?.status){
                toast.success("Gallery Updated Successfully.")
            }
        })
    }

    return <>
        <Card>
            <Card.Body>
                <Row className="mb-4">
                    <Col md={6} style={{zIndex: '3'}}>
                        <label>{Translate[lang].type}</label>
                        <Select
                            value={type}
                            name="type"
                            placeholder={Translate[lang]?.select}
                            options={typeOptions}
                            onChange={(e)=> {
                                setType(e)
                            }}
                        />
                    </Col>
                </Row>
                {type?.value !== 'fashion' && (!loading  ? data?.map((item,index) => {
                    return <Row key={index}>
                        <Col md={12}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                    <input type="file" disabled={upload} accept="image/*" className="w-100 h-100 cursor-pointer"  onChange={(e) => {
                                        fileHandler(e, index, 'one')
                                    }}/> 					
                                </div>
                                {!!item.one?.src && <button 
                                    className="delete-image"
                                    style={{zIndex: '8'}}
                                    type="button"
                                    onClick={()=>{
                                        let update= data?.map((item, ind)=>{
                                            if(ind === index){
                                                return{
                                                    ...item,
                                                    one: { src: "", loading: false}
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        setData([...update])
                                    }}
                                >
                                    <i className="la la-trash text-danger"></i>
                                </button>}
                                <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.one?.src && !item?.one?.loading) && <img src={item.one?.src} alt='icon' />}
                                            {(!item.one?.src && !item?.one?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.one?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            disabled={upload}
                                            accept="image/*"
                                            className="w-100 h-100 cursor-pointer" 
                                            onChange={(e) => {
                                                fileHandler(e, index, 'two')
                                            }}
                                        /> 					
                                    </div>
                                    {!!item.two?.src && <button 
                                        className="delete-image"
                                        type="button"
                                        onClick={()=>{
                                            let update= data?.map((item, ind)=>{
                                                if(ind === index){
                                                    return{
                                                        ...item,
                                                        two: { src: "", loading: false}
                                                    }
                                                } else {
                                                    return item
                                                }
                                            })
                                            setData([...update])
                                        }}
                                    >
                                        <i className="la la-trash text-danger"></i>
                                    </button>}
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.two?.src && !item?.two?.loading) && <img src={item.two?.src} alt='icon' />}
                                            {(!item.two?.src && !item?.two?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.two?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                    <input 
                                        type="file" 
                                        accept="image/*"
                                        disabled={upload}
                                        className="w-100 h-100 cursor-pointer"  
                                        onChange={(e) => {
                                            fileHandler(e, index, 'three')
                                        }} /> 					
                                </div>
                                {!!item.three?.src && <button 
                                    className="delete-image"
                                    type="button"
                                    onClick={()=>{
                                        let update= data?.map((item, ind)=>{
                                            if(ind === index){
                                                return{
                                                    ...item,
                                                    three: { src: "", loading: false}
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        setData([...update])
                                    }}
                                >
                                    <i className="la la-trash text-danger"></i>
                                </button>}
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.three?.src && !item?.three?.loading) && <img src={item.three?.src} alt='icon' />}
                                            {(!item.three?.src && !item?.three?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.three?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }) : <div style={{minHeight: '350px'}}>
                <Loader />
            </div>)}
            {type?.value === 'fashion' && (!loading ? <Row>
                {data?.map((item,index) => {
                    return <React.Fragment key={index}>
                        <Col md={6}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                    <input type="file" disabled={upload} accept="image/*" className="w-100 h-100 cursor-pointer"  onChange={(e) => fileHandler(e, index, 'one')}/> 					
                                </div>
                                {!!item.one?.src && <button 
                                    className="delete-image"
                                    style={{zIndex: '8'}}
                                    type="button"
                                    onClick={()=>{
                                        let update= data?.map((item, ind)=>{
                                            if(ind === index){
                                                return{
                                                    ...item,
                                                    one: { src: "", loading: false}
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        setData([...update])
                                    }}
                                >
                                    <i className="la la-trash text-danger"></i>
                                </button>}
                                <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!item.one?.src && !item?.one?.loading) && <img src={item.one?.src} alt='icon' />}
                                            {(!item.one?.src && !item?.one?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.one?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                    </React.Fragment>
                })}
            </Row> : <div style={{minHeight: '350px'}}>
                <Loader />
            </div>)}
                <div className="d-flex justify-content-between mt-5">
                    <Button 
                        variant='outline-primary'
                        onClick={() => {
                            if(type?.value === 'fashion'){
                                setData([...data, {one: {src: '', loading: false}}])
                            } else {
                                setData([...data, {one: {src: '', loading: false}, two: {src: '', loading: false}, three: {src: '', loading: false}}])
                            }
                        }}
                    >{Translate[lang].add_new_value}</Button>
                    <Button variant='primary' onClick={()=> onSubmit()}>{Translate[lang].submit}</Button>
                </div>
            </Card.Body>
        </Card>
    </>
}
export default Gallery;