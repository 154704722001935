import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import BaseService from "../../../../services/BaseService";
import Loader from "../../../common/Loader";
import uploadImg from "../../../../images/upload-img.png";
import '../style.scss'
import ServicesService from "../../../../services/ServicesService";
import { useLocation, useNavigate } from "react-router-dom";

const AddServices = ()=>{
    const [formData, setFormData] = useState({
        title_ar: '',
        title_en: '',
        title_es: '',
        title_it: '',
        title_ru: '',
        title_fr: '',
        title_de: '',
        image: {src: '', loading: false}
    })
    const [isAdd, setIsAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state=> state.auth.lang)
    const servicesService = new ServicesService()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if(!location.state){
            setIsAdd(true)
        } else {
            setIsAdd(false)
            setFormData({
                id: location.state?.id,
                title_en: location.state?.title_en,
                title_ar: location.state?.title_ar,
                title_es: location.state?.title_es,
                title_it: location.state?.title_it,
                title_ru: location.state?.title_ru,
                title_fr: location.state?.title_fr,
                title_de: location.state?.title_de,
                image: {src: location.state?.image, loading: false}
            })
        }
    },[location])

    const fileHandler = (e) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        setFormData({
            ...formData, image: {src: '', loading: true}
        })

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.data?.status) {
            setFormData({
                ...formData, image: {src: res.data.url, loading: false}
            })
          }
        });
    };

    const submit = (e) =>{
        e.preventDefault();
        let data ={
            title_ar: formData?.title_ar,
            title_en: formData?.title_en,
            image: formData.image.src
        }
        if(!!formData?.title_es) data['title_es'] = formData?.title_es;
        if(!!formData?.title_it) data['title_it'] = formData?.title_it;
        if(!!formData?.title_ru) data['title_ru'] = formData?.title_ru;
        if(!!formData?.title_fr) data['title_fr'] = formData?.title_fr;
        if(!!formData?.title_de) data['title_de'] = formData?.title_de;
            

        setLoading(true)
        if(isAdd){
            servicesService.create(data)?.then(res=>{
                if(res && res?.status === 201){
                    toast.success('Services Added Successfully')
                    navigate('/services')
                }
                setLoading(false)
            }).catch(()=>setLoading(false))
        } else {
            servicesService.update(formData?.id, data)?.then(res=>{
                if(res && res?.status === 200){
                    toast.success('Services Updated Successfully')
                    navigate('/services')
                }
                setLoading(false)
            }).catch(()=>setLoading(false))
        }
    }

    return(
        <Card>
            <Card.Body>
                <AvForm className='form-horizontal' onValidSubmit={submit}>
                    <Row>
                        <Col md={6}>
                            <AvField
                                label={`${Translate[lang].english_title}*`}
                                type='text'
                                placeholder={Translate[lang]?.english_title}
                                bsSize="lg"
                                name='english_title'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.title_en}
                                onChange={(e) => setFormData({...formData, title_en: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={`${Translate[lang].arabic_title}*`}
                                type='text'
                                placeholder={Translate[lang]?.arabic_title}
                                bsSize="lg"
                                name='arabic_title'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.title_ar}
                                onChange={(e) => setFormData({...formData, title_ar: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].spanish_title}`}
                                name ={`title_es`}
                                type="text"
                                value={formData.title_es}
                                placeholder={Translate[lang].spanish_title}
                                onChange={(e) => setFormData({...formData, title_es: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].italian_title}`}
                                name ={`title_it`}
                                type="text"
                                value={formData.title_it}
                                placeholder={Translate[lang].italian_title}
                                onChange={(e) => setFormData({...formData, title_it: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].russian_title}`}
                                name ={`title_ru`}
                                type="text"
                                value={formData.title_ru}
                                placeholder={Translate[lang].russian_title}
                                onChange={(e) => setFormData({...formData, title_ru: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].french_title}`}
                                name ={`title_fr`}
                                type="text"
                                value={formData.title_fr}
                                placeholder={Translate[lang].french_title}
                                onChange={(e) => setFormData({...formData, title_fr: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2">
                            <AvField
                                label ={`${Translate[lang].german_title}`}
                                name ={`title_de`}
                                type="text"
                                value={formData.title_de}
                                placeholder={Translate[lang].german_title}
                                onChange={(e) => setFormData({...formData, title_de: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="form-group mb-2"></Col>

                        <Col md={6}>
                            <label>{Translate[lang].image}</label>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            accept="image/*"
                                            className="w-100 h-100 cursor-pointer"  
                                            onChange={(e) => fileHandler(e)} /> 					
                                    </div>
                                    <div className="avatar-preview m-auto">
                                        <div>
                                            {(!!formData.image?.src && !formData?.image?.loading) && <img src={formData.image?.src} alt='icon' />}
                                            {(!formData.image?.src && !formData?.image?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {formData?.image?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
            <div className="d-flex justify-content-between mt-5">
                <Button onClick={()=> navigate('/services')} variant="danger light">
                {Translate[lang]?.close}
                </Button>
                <Button 
                        variant="primary" 
                        type='submit'
                        disabled={loading}
                    >{isAdd ? Translate[lang]?.add : Translate[lang]?.edit}
                </Button>
            </div>
            </AvForm>
            </Card.Body>
        </Card>)
}

export default AddServices;