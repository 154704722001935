import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import './style.scss'
import AddAppointmentTypesModal from "./AddAppointmentTypesModal";
import AppointmentTypesService from "../../../services/AppointmentTypesService";

const AppointmentTypes = () => {
    const [data, setData] = useState([])
    const [item, setItem] = useState({})
    const [hasData, setHasData] = useState(null)
    const [search, setSearch] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [modal, setModal] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const Auth = useSelector((state) => state.auth?.auth);
    const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
    const appointmentTypesService = new AppointmentTypesService()

    useEffect(()=> {
      setLoading(true)
      appointmentTypesService?.getList().then(res=>{
        if(res?.status === 200){
          setData([...res.data?.data]);
          if (res.data?.data?.length > 0) {
            setHasData(1);
          } else {
            setHasData(0);
          }
        }
        setLoading(false);
      })
    },[shouldUpdate])

  return (
    <div className="department">
      <Card className="mb-3">
        <Card.Body className="search-card d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-50"></div>
          <div>
            <Button onClick={()=> {
              setItem('')
              setModal(true)
            }} variant="primary" className='h-75'>
              <i className="la la-plus mx-1"></i>
              {Translate[lang]?.add} {Translate[lang]?.type}
            </Button>
          </div>
        </Card.Body >
      </Card>
      
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body className={`${hasData === 0 && 'text-center'} `}>
            {loading && <div style={{height: '300px'}}>
                <Loader />
              </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.title}</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="table-body">
                    {data?.map((item, index) =>{
                        return <CardItem 
                            index= {index}
                            key= {index}
                            item={item}
                            setModal={setModal}
                            setItem={setItem}
                            setShouldUpdate={setShouldUpdate}
                        />
                    })}
                </tbody>
              </Table>}
              {hasData === 0 && <NoData />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {modal && <AddAppointmentTypesModal modal={modal} setModal={()=> setModal(false)} item={item} setShouldUpdate={setShouldUpdate} />}
    </div>
  );
};

export default AppointmentTypes;
