import { useEffect, useState } from "react";
import ClientsService from "../../../services/ClientsService";
import BaseService from "../../../services/BaseService";
import { toast } from "react-toastify";
import { Button, Card } from "react-bootstrap";
import { Translate } from "../../Enums/Tranlate";
import { AvForm } from "availity-reactstrap-validation";
import Loader from "../../common/Loader";
import { useSelector } from "react-redux";
import uploadImg from '../../../images/upload-img.png'
import './style.scss'

const OurClients = () => {
    const [formData, setFormData] = useState({
        our_clients: [
            { src: "", loading: false },
            { src: "", loading: false },
            { src: "", loading: false },
            { src: "", loading: false },
            { src: "", loading: false },
        ],
    })
    const lang = useSelector((state) => state.auth?.lang);
    const [clientsLoading, setClientsLoading] = useState(false)
    const clientsService = new ClientsService()

    useEffect(()=> {
        clientsService?.getList().then(res=>{
            if(res?.status === 200){
                let data = {}
                if(res?.data?.data?.length > 5){
                  data['our_clients'] = res?.data?.data?.map(cli => {
                    return {id: cli?.id, src: cli?.image, loading: false }
                  })
                } else {
                  data['our_clients'] = Array.from({ length: 5 }, (_, index) => {
                    let arr = res?.data?.data;
                    if(arr[index]){
                      return {id: arr[index]?.id, src: arr[index]?.image, loading: false }
                    } else {
                      return { src: "", loading: false }
                    }
                  })
                }
                setFormData(data)
              }
        })
    },[])

    const fileHandler = (e, index, type) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        let update = formData?.our_clients?.map((data, ind)=> {
            if(ind === index){
              return {src: "", loading: true}
            } else{
               return data
            }
        })
        setFormData({ our_clients: update });

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.data?.status) {
            let update = formData?.our_clients?.map((data, ind)=> {
                if(ind === index){
                  return {src: res.data.url, loading: false}
                } else{
                   return data
                }
            })
            setFormData({
                our_clients: update
            });
          }
        });
    };

    const handleClients = (e) =>{
        e.preventDefault();
    
        setClientsLoading(true);
        let data = {
          images: formData?.our_clients?.filter(res=> !!res.src)?.map(res=> res?.src)
        };
    
        clientsService.create(data)?.then((res) => {
          if (res?.status === 201) {
            toast.success("Clients Added Successfully");
          }
          setClientsLoading(false);
        }).catch(()=> setClientsLoading(false));
    }

    const deleteClientImg = (id) =>{  
        setClientsLoading(true);
    
        clientsService.remove(id)?.then((res) => {
          if (res?.status === 200) {
            toast.success("Image Removed Successfully");
            let update = formData?.our_clients?.map((data, ind)=> {
              if(data.id === id){
                return {src: "", loading: false}
              } else{
                 return data
              }
            })
            setFormData({ ...formData,  our_clients: update });
          }
          setClientsLoading(false);
        }).catch(()=> setClientsLoading(false));
    }

    if(clientsLoading){
      return <Card style={{minHeight: '380px'}}>
        <Card.Body>
          <Loader />
        </Card.Body>
      </Card>
    }

    return <Card>
      <Card.Body>
        <AvForm onValidSubmit={handleClients}>
         <div className="our-clients mt-3">
            <h3>{Translate[lang]?.our_clients}</h3>
            <div className="row mt-4">
                {formData?.our_clients?.map((client, index)=> {
                    return <div className="col-lg-2 col-sm-4 mb-3" key={index}>
                    <div className="image-placeholder">
                      <div className="avatar-edit w-100 h-100">
                        <input
                          type="file"
                          className="w-100 h-100 d-block cursor-pointer"
                          style={{opacity: '0'}}
                          onChange={(e) => fileHandler(e, index, 'clients')}
                          id={`imageUpload${index}`}
                        />
                        {/* <label htmlFor={`imageUpload${index}`} name=""></label> */}
                      </div>
                      {!!client?.id && <button
                        className="delete-img"
                        type="button"
                        style={{
                          left: lang === 'ar' ? '-13px' : "auto",
                          right: lang === 'en' ? '-13px' : "auto",
                        }}
                        onClick={() => deleteClientImg(client?.id)}
                      >
                        <i className="la la-trash"></i>
                      </button>}
                      <div className="avatar-preview">
                        {!!client.src ? (
                          <div id={`imagePreview${index}`}>
                            <img
                              id={`saveImageFile${index}`}
                              src={client?.src}
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div id={`imagePreview${index}`}>
                            {(!client.src && client.loading)  && <Loader></Loader>}
                            {(!client?.src && !client.loading) && (
                              <img
                                id={`saveImageFile${index}`}
                                src={uploadImg}
                                alt="icon"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                })}
                <div className="col-lg-2 col-sm-4 d-flex" style={{
                    alignItems: "center", justifyContent: "center"
                }}>
                    <div className="add-client">
                        <i className="la la-plus cursor-pointer" onClick={()=>{
                            setFormData({...formData, our_clients: [...formData.our_clients, { src: "", loading: false }]})
                        }}></i>
                    </div>
                </div>
            </div>
         </div>

         <div className="d-flex justify-content-between mt-4">
            <Button variant="primary" type="submit" disabled={clientsLoading}>{Translate[lang]?.submit}</Button>
         </div>
         </AvForm>
      </Card.Body>
    </Card>
}
export default OurClients;