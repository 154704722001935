import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import AppointmentTypesService from "../../../../services/AppointmentTypesService";

const AddAppointmentTypesModal = ({modal, setModal, item, setShouldUpdate})=>{
    const [loading, setLoading] = useState(false)
    const [isAdd, setIsAdd] = useState(false)
    const [formData, setFormData] = useState({
        name: ''
    })
    const appointmentTypesService = new AppointmentTypesService()
    const lang = useSelector(state=> state.auth.lang)

    useEffect(()=>{
        if(!!item){
            setIsAdd(false)
            setFormData({
                id: item?.id,
                name: item?.type
            })
        } else {
            setIsAdd(true)
        }
    },[])

    const submit = () =>{
        let data ={
            type: formData?.name
        }
        setLoading(true)
        if(isAdd){
            appointmentTypesService.create(data)?.then(res=>{
                if(res?.status === 201){
                    toast.success('Added Successfully')
                    setModal()
                    setShouldUpdate(prev=> !prev)
                }
                setLoading(false)
            })
        } else {
            appointmentTypesService.update(formData?.id, data)?.then(res=>{
                if(res?.status === 200){
                    toast.success('Updated Successfully')
                    setModal()
                    setShouldUpdate(prev=> !prev)
                }
                setLoading(false)
            })
        }
    }

    return(
        <Modal className="fade" show={modal} onHide={setModal}>
            <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{isAdd ? Translate[lang].add : Translate[lang].edit} {Translate[lang].appointment_types}</Modal.Title>
            <Button
                variant=""
                className="close"
                onClick={setModal}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                
            <Row>
                <Col md={12}>
                    <AvField
                        label={Translate[lang].name}
                        type='text'
                        name='name'
                        placeholder={Translate[lang].name}
                        value={formData?.name}
                        onChange={e=> setFormData({...formData, name: e.target.value})}
                    />
                </Col>
            </Row>
                
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setModal} variant="danger light">
            {Translate[lang].close}
            </Button>
            <Button 
                disabled={loading}
                variant="primary" 
                type='submit'
                >{isAdd ? Translate[lang].add : Translate[lang].edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddAppointmentTypesModal;