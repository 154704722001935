import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SocialMediaService from "../../../../services/SocialMediaService";
import Loader from "../../../common/Loader";
import { SocialMediaLinks } from "../../../Enums/SocialMedia";
import { Translate } from "../../../Enums/Tranlate";

const SocialMedia = ()=>{
    const [links, setLinks] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const socialMediaService = new SocialMediaService()

    useEffect(()=>{
        setLoadingData(true)
        socialMediaService?.getList()?.then(res=>{
            if(res.status === 200 && res.data?.data){
                setLinks({
                    ...res.data?.data,
                    snapchat: res?.data?.data?.facebook
                })
            } else{
                let values={}
                SocialMediaLinks?.map(link=> values[link.value]= '')
                setLinks({...values})
            }
            setLoadingData(false)
        })
    },[])

    const inputHandler =(e)=>{
        setLinks({...links,[e.target.name]: e.target.value})
    }

    const onSubmit = (e)=> {
        e.preventDefault()

        let data = {}
        if(!!links.snapchat) data['facebook'] = links.snapchat
        if(!!links.instagram) data['instagram'] = links.instagram
        if(!!links.whatsapp) data['whatsapp'] = links.whatsapp
        if(!!links.tiktok) data['tiktok'] = links.tiktok
        if(!!links.linked_in) data['linked_in'] = links.linked_in
        if(!!links.you_tube) data['you_tube'] = links.you_tube
        if(!!links.gmail) data['gmail'] = links.gmail
        if(!!links.address_link) data['address_link'] = links.address_link

        setLoading(true)
        socialMediaService?.create(data)?.then(res=>{
            if(res.status === 201){
                toast?.success('Added Social Links Successfully')
            }
            setLoading(false)
        }).catch(error=> {
            toast.error(error)
            setLoading(false)
        })
    }

    if(loadingData){
        return <Card className="py-5" style={{height: '300px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
      </Card>
    }

    return(<>
    <Card>
        <Card.Body className="position-relative">
            <form onSubmit={onSubmit}>
                <Row className="mb-3">
                    {SocialMediaLinks?.map((link, index)=>{
                        return <Col md={6} className='mb-3' key={index}>
                            <label className="text-label">
                                {Translate[lang][link.value]} :
                            </label>
                            <input
                                type="text"
                                name={link.value}
                                className="form-control"
                                placeholder={`${Translate[lang][link.value]} Link`}
                                value={links[link?.value]}
                                onChange={(e)=> inputHandler(e)}
                            />
                        </Col>
                    })}
                </Row>
            <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit" disabled={loading}>
                    {Translate[lang].submit}
                </Button>
            </div>
            </form>
        </Card.Body>
    </Card>
    </>)
}
export default SocialMedia;