import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeleteModal from "../../../common/DeleteModal";
import { Translate } from "../../../Enums/Tranlate";
import ServicesService from "../../../../services/ServicesService";
import { useNavigate } from "react-router-dom";

const CardItem = ({item, setItem, index, setAddModal,setViewModal, setShouldUpdate}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const servicesService = new ServicesService()
    const navigate = useNavigate()

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                <img 
                    src={item?.image} 
                    style={{
                        width: '70px'
                    }}
                    alt='service' 
                />
            </td>
            <td>{lang === 'en' ? item.title_en.slice(0,60) : item.title_ar.slice(0,60)} {!!item.title_en.slice(60) ? '...' : ''} {!!item.title_ar.slice(60) ? '...' : ''}</td>
            <td>
                {isExist('services') && <Dropdown>
                    <Dropdown.Toggle
                        className="light sharp i-false"
                    >
                        <i className="la la-ellipsis-v" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> {
                           navigate('/services/add-services', {state: item})
                        }}> 
                            <i className='la la-edit'></i> {Translate[lang]?.edit}
                        </Dropdown.Item>
                        <Dropdown.Item onClick={()=> setDeleteModal(true)}>
                        <i className='la la-trash'></i> {Translate[lang]?.delete}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </td>
            {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={lang === 'en' ? item?.title_en : item?.title_ar}
                      deletedItem={item}
                      modelService={servicesService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                    />}
            </tr>
    )
}
export default CardItem;