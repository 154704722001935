import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Error404 from "./common/Error404";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";

import Admins from "./pages/Admin";
import AddAdmin from "./pages/Admin/AddAdmin";
import Permission from "./pages/Rules";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Video from "./pages/Video";
import Services from "./pages/Services";
import Appointment from "./pages/Appointment";
import Blogs from "./pages/Blogs";
import SocialMedia from "./pages/Setting/SocialMedia";
import Static from "./pages/Setting/StaticPages/Static";
import AddBlogs from "./pages/Blogs/AddBlogs";
import AddServices from "./pages/Services/AddServicesModal";
import OurClients from "./pages/OurClients";
import SpecificBlock from "./pages/BlockDate";
import AppointmentTypes from "./pages/AppointmentTypes";

const Markup = () => {
  const allroutes = [
    { url: "", component: <Admins /> },

    // Admins
    { url: "admins", component: <Admins /> },
    { url: "admins/add-admins", component: <AddAdmin /> },
    { url: "admins/edit-admin/:id/:name", component: <AddAdmin /> },

    // Rules
    { url: "rules", component: <Permission /> },
    { url: "rules/:id", component: <Permission /> },

    // Home
    { url: "home", component: <Home /> },

    // Gallery
    { url: "gallery", component: <Gallery /> },

    // Video
    { url: "video", component: <Video /> },

    // Services
    { url: "services", component: <Services /> },
    { url: "services/add-services", component: <AddServices /> },

    // Appointment
    { url: "appointment", component: <Appointment /> },

    // BlockDate
    { url: "specific-block", component: <SpecificBlock /> },

    // Appointment Types
    { url: "appointment-types", component: <AppointmentTypes /> },

    // OurClients
    { url: "our_clients", component: <OurClients /> },

    // Blogs
    { url: "blogs", component: <Blogs /> },
    { url: "blogs/add-blogs", component: <AddBlogs /> },

    // Profile
    { url: "profile", component: <Profile /> },

    // SocialMedia
    { url: "social", component: <SocialMedia /> },

    // Static
    { url: "page", component: <Static /> },

    // Error
    { url: "*", component: <Error404 /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-error-404" element={<Error404 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  return (
    <div id="main-wrapper" className={`show `}>
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
