import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Translate } from "../../Enums/Tranlate";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import uploadImg from '../../../images/upload-img.png'
import BaseService from "../../../services/BaseService";
import Select from 'react-select';
import './style.css'
import VideoService from "../../../services/VideoService";
import { toast } from "react-toastify";

const Video = () => {
    const [data, setData] = useState([
        {one: {src: '', loading: false}, two: {src: '', loading: false}, three: {src: '', loading: false}},
    ])
    const typeOptions = [
        {label: 'Fashion and Beauty', value: 'fashion_and_beauty'},
        {label: 'Product', value: 'product'},
        {label: '3d Animation', value: '3d_animation'},
        {label: 'Podcast & Tv show', value: 'podcast_tv_show'},
        {label: 'Commercials Advertising', value: 'commercials_advertising'},
        {label: 'Behind the Scene', value: 'behind_the_scene'},
        {label: 'Other', value: 'other'},
    ]
    const [type, setType] = useState('')
    const [loading, setLoading] = useState(false)
    const videoService = new VideoService()
    const lang = useSelector((state) => state.auth?.lang);

    const fileHandler = (e, index, typ) => {
        if(!type){
            toast.error('Select Type')
            return
        }
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        let update = data?.map((item, itemIndex) => {
            if(itemIndex === index){
                return {
                    ...item,
                    [typ]: {src: '', loading: true}
                }
            } else {
                return item
            }
        })
        setData(update)

        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.data?.status) {
              let update = data?.map((item, itemIndex) => {
                if(itemIndex === index){
                    return {
                        ...item,
                        [typ]: {src: res.data.url, loading: false}
                    }
                } else {
                    return item
                }
            })
            setData(update)
          } else {
            let update = data?.map((item, itemIndex) => {
                if(itemIndex === index){
                    return {
                        ...item,
                        [typ]: {src: '', loading: false}
                    }
                } else {
                    return item
                }
            })
            setData(update)
          }
        });
    };

    useEffect(()=> {
        if(!!type?.value){
            let params = {
                type: type?.value
            }
            setLoading(true)
            videoService?.getList(params).then(res=>{
                if(res?.status === 200){
                    let response = res?.data?.data?.map(info => {
                        return {one: {src: info.one, loading: false}, two: {src: info.two, loading: false}, three: {src: info.three, loading: false}}
                    })
                    if(response?.length > 0) {
                        setData(response)
                    } else {
                        setData([{one: {src: '', loading: false}, two: {src: '', loading: false}, three: {src: '', loading: false}}])
                    }
                }
                setLoading(false)
            }).catch(()=> setLoading(false))
        }
    },[type])

    const onSubmit = () => {
        if(!type){
            toast.error('Select Type')
            return
        }
        if(data?.filter(res=> !!res?.one?.src && !!res?.two?.src && !!res?.three?.src)?.length === 0){
            toast.error("Upload Videos")
            return
        }
        let body = {
            video: data?.filter(res=> !!res?.one?.src && !!res?.two?.src && !!res?.three?.src)?.map(res=>{
                return {
                    one: res?.one?.src,
                    two: res?.two?.src,
                    three: res?.three?.src,
                }
            }),
            type: type?.value
        }
        videoService.create(body).then(res=>{
            if(res?.status){
                toast.success("Video Updated Successfully.")
            }
        })
    }

    return <div className="video">
        <Card>
            <Card.Body>
                <Row className="mb-4">
                    <Col md={6} style={{zIndex: '8'}}>
                        <label>{Translate[lang].type}</label>
                        <Select
                            value={type}
                            name="type"
                            placeholder={Translate[lang]?.select}
                            options={typeOptions}
                            onChange={(e)=> {
                                setType(e)
                            }}
                        />
                    </Col>
                </Row>
                {!loading ? data?.map((item,index) => {
                    return <Row key={index}>
                        <Col md={6}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                    <input type="file" accept="video/*" className="w-100 h-100 cursor-pointer"  onChange={(e) => fileHandler(e, index, 'one')}/> 					
                                </div>
                                {!!item.one?.src && <button 
                                    className="delete-image"
                                    type="button"
                                    onClick={()=>{
                                        let update= data?.map((item, ind)=>{
                                            if(ind === index){
                                                return{
                                                    ...item,
                                                    one: { src: "", loading: false}
                                                }
                                            } else {
                                                return item
                                            }
                                        })
                                        setData([...update])
                                    }}
                                >
                                    <i className="la la-trash text-danger"></i>
                                </button>}
                                    <div className="avatar-preview reels m-auto" style={{zIndex: !!item.one?.src ? '7' : ''}}>
                                        <div>
                                            {(!!item.one?.src && !item?.one?.loading) &&  <video style={{width: '100%', height: '100%'}} controls>
                                                    <source src={item.one?.src} type="video/mp4" />
                                                    <source src={item.one?.src} type="video/ogg" />
                                            </video>}
                                            {(!item.one?.src && !item?.one?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.one?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            accept="video/*" 
                                            className="w-100 h-100 cursor-pointer" 
                                            onChange={(e) => fileHandler(e, index, 'two')}
                                        /> 					
                                    </div>
                                    {!!item.two?.src && <button 
                                        className="delete-image"
                                        type="button"
                                        onClick={()=>{
                                            let update= data?.map((item, ind)=>{
                                                if(ind === index){
                                                    return{
                                                        ...item,
                                                        two: { src: "", loading: false}
                                                    }
                                                } else {
                                                    return item
                                                }
                                            })
                                            setData([...update])
                                        }}
                                    >
                                        <i className="la la-trash text-danger"></i>
                                    </button>}
                                    <div className="avatar-preview reels m-auto" style={{zIndex: !!item.two?.src ? '8' : ''}}>
                                        <div>
                                            {(!!item.two?.src && !item?.two?.loading) && <video style={{width: '100%', height: '100%'}} controls>
                                                    <source src={item.two?.src} type="video/mp4" />
                                                    <source src={item.two?.src} type="video/ogg" />
                                            </video>}
                                            {(!item.two?.src && !item?.two?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.two?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="image-placeholder">	
                                <div className="avatar-edit w-100 h-100">
                                        <input 
                                            type="file" 
                                            accept="video/*" 
                                            className="w-100 h-100 cursor-pointer"  
                                            onChange={(e) => fileHandler(e, index, 'three')} /> 					
                                    </div>
                                    {!!item.three?.src && <button 
                                        className="delete-image"
                                        type="button"
                                        onClick={()=>{
                                            let update= data?.map((item, ind)=>{
                                                if(ind === index){
                                                    return{
                                                        ...item,
                                                        three: { src: "", loading: false}
                                                    }
                                                } else {
                                                    return item
                                                }
                                            })
                                            setData([...update])
                                        }}
                                    >
                                        <i className="la la-trash text-danger"></i>
                                    </button>}
                                    <div className="avatar-preview reels m-auto" style={{zIndex: !!item.three?.src ? '8' : ''}}>
                                        <div>
                                            {(!!item.three?.src && !item?.three?.loading) && <video style={{width: '100%', height: '100%'}} controls>
                                                    <source src={item.three?.src} type="video/mp4" />
                                                    <source src={item.three?.src} type="video/ogg" />
                                            </video>}
                                            {(!item.three?.src && !item?.three?.loading) && 
                                            <img  
                                                src={uploadImg} alt='icon'
                                                style={{
                                                    width: '80px', height: '80px',
                                                }}
                                            />}
                                            {item?.three?.loading && <Loader />}
                                        </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }) : <div style={{minHeight: '350px'}}>
                    <Loader />
                </div>}
                <div className="d-flex justify-content-between mt-5">
                    <Button 
                        variant='outline-primary'
                        onClick={() => setData([...data, {one: {src: '', loading: false}, two: {src: '', loading: false}, three: {src: '', loading: false}}])}
                    >{Translate[lang].add_new_value}</Button>
                    <Button variant='primary' onClick={onSubmit}>{Translate[lang].submit}</Button>
                </div>
            </Card.Body>
        </Card>
    </div>
}
export default Video;