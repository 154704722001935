export const Rules = [
  { label: "Admin", value: "admin" },
  { label: "Rules", value: "rules" },
  { label: "Home", value: "home" },
  { label: "Gallery", value: "gallery" },
  { label: "video", value: "video" },
  { label: "Services", value: "services" },
  { label: "Appointment", value: "appointment" },
  { label: "Blogs", value: "blogs" },
  {label: 'Static Pages', value: 'static_pages'},
];
