export const MenuList = [
  // Admins
  {
    title: "Admins Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-user-shield"></i>,
    to: "admins",
    text: "admins",
  },
  // Rules
  {
    title: "Rules",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-shield"></i>,
    to: "rules",
    text: "rules",
  },
  // Home
  {
    title: "Home",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-slack" />,
    to: "home",
    text: "home",
  },

  // Gallery
  {
    title: "Gallery",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-image" />,
    to: "gallery",
    text: "gallery",
  },
  
  // Video
  {
    title: "Video",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-video" />,
    to: "video",
    text: "video",
  },
  // Services
  {
    title: "Services",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-pie-chart" />,
    to: "services",
    text: "services",
  },
  // Appointment
  {
    title: "Appointment",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-calendar-alt" />,
    to: "appointment",
    text: "appointment",
  },
  // Specific Block
  {
    title: "Specific Block",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-notepad" />,
    to: "specific-block",
    text: "specific_block",
  },
  // Appointment Types
  {
    title: "Appointment Types",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-cubes" />,
    to: "appointment-types",
    text: "appointment_types",
  },
  // Blogs
  {
    title: "Blogs",
    classsChange: "mm-collapse",
    iconStyle: <i className="la la-blog" />,
    to: "blogs",
    text: "blogs",
  },
   // Setting
   {
    title:'Setting',
    text:'setting',
    classsChange: 'mm-collapse',
    iconStyle: <i className="la la-wrench" />,
    content : [
        {
            title:'Page',
            text:'page',
            to: 'page',
            rule: 'home',
        },
        {
          title: "Our Clients",
          text: "our_clients",
          to: "our_clients",
          rule: "home",
        },
        {
          title: "Social Media",
          text: "social_media",
          to: "social",
          rule: "home",
        }
    ]
},

  // <i className='la la-cubes'></i>,
  // <i className='la la-cube'></i>,
  // <i className="la la-slack" />,
  // <i className="flaticon-381-notepad" />,
  // <i className="la la-qrcode"></i>
  // <i className='la la-truck'></i>
  // <i className='la la-pie-chart'></i>
  // <i className='la la-dollar'></i>
  // <i className="la la-wrench" />
  // <i className="la la-simplybuilt" />
  // <i className='la la-bell'></i>
];
